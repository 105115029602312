import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FiPhone } from 'react-icons/fi';
import { FiClock } from 'react-icons/fi';

function App() {
  const [message, setMessage] = useState('');

  const fetchData = async () => {
    try {
      const response = await axios.get('https://api.sheety.co/65e7655879ddd8cb0d451e573e53fc83/okPaperSign/sheet1');
      const data = response.data;
      console.log(data['sheet1'][0].message);
      setMessage(data['sheet1'][0].message);
  
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="App">
      <div className="Aligner">
        <header className="App-header"></header>
        <div className="Aligner-item message">
          {message.toString().toLowerCase() === 'open' ? (
            <h1 className="open">OPEN</h1>
          ) : null}
          {message.toString().toLowerCase() === 'closed' ? (
            <h1 className="closed">CLOSED</h1>
          ) : null}
          {message != null && message.toString().toLowerCase() !== 'open' && message.toString().toLowerCase() !== 'closed' && message !== '0' ? (
            <p>{message}</p>
          ) : null}
        </div>
        <div className="Bottom">
          <img src="https://ajgmultimedia.s3.us-west-2.amazonaws.com/ok_tire.jpg" className="App-logo" alt="logo" />
          <div className="flex-container">
            <div>
              <h2>Business Hours</h2>
              <p>
                <FiClock /> Thursday 8:00am - 5:00pm
              </p>
            </div>
            <div className="phones">
              <p>
                <FiPhone /> (208) 766-2950 (shop)
              </p>
              <p>
                <FiPhone /> (208) 406-2834 (cell)
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;